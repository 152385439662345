

































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getClientsLight, getRemoteClients, getRemoteProperties, getRemoteUsers } from '@/api/helpers'
import {errorMsg, getTranslations, hasRoles, locales, validateForm} from '@/utils'
import { getRemoteGroups } from '@/api/groups'
import Sticky from '@/components/Sticky/index.vue'
import Tinymce from '@/components/Tinymce/index.vue'
import { AppModule, DeviceType } from '@/store/modules/app'
import { Form } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { emailPreview, saveEmailWithMedia, sendEmail } from '@/api/emails'
import {
  createFilter,
  FilterOperator,
  FilterType
} from '@/utils/filter'
import { getPublicAndOfflineRequiredFilters } from '@/utils/property'

@Component({
  name: 'InternalMailList',
  components: {
    Sticky,
    Tinymce
  }
})

export default class extends Vue {
  private languages = locales
  private language = locales[0]
  private propertiesLoading = false
  private listLoading = false
  private previewEmail: any = null
  private items = []
  private properties = []
  private files: any = []
  private sendOptions = [
    'clients',
    'clients_groups',
    'users',
    'users_groups'
  ]

  private userGroups = [
    'office',
    'secretariat',
    'broker',
    'company',
    'offices_thessaloniki',
    'offices_athens',
    'support'
  ]

  private rules = {
    'meta.subject': [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    'meta.choose_from': [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    'meta.recipients': [
      { type: 'array', message: 'Please select array', trigger: 'change' },
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ]
  }

  private email = {
    user_id: UserModule.id,
    office_id: UserModule.officeId,
    meta: {
      subject: '',
      html: '',
      choose_from: 'clients',
      language: locales[0],
      attachments: [],
      recipients: [],
      properties: []
    }
  }

  get isInPreviewMode() {
    return !!this.previewEmail
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get sender() {
    return UserModule.email
  }

  get formattedUserGroups() {
    if (hasRoles(['super_admin', 'central_secretariat'], UserModule.roles)) {
      this.userGroups.push(...[
        'all_broker',
        'all_secretariat',
        'all_collaborator'
      ])
      return this.userGroups
    }
    return this.userGroups
  }

  created() {
    const query: any = this.$route.query || {}
    if (query && Object.keys(query).length) {
      this.email.meta.subject = query.subject || ''
      this.email.meta.choose_from = query.choose_from || this.email.meta.choose_from
      this.email.meta.properties = query.properties || []
      if (query.recipients) {
        getClientsLight(createFilter([{
          type: FilterType.field,
          key: 'id',
          value: query.recipients[0],
          operator: FilterOperator.eq
        }])).then(({ data }) => {
          this.items = data.collection
          this.email.meta.recipients = data.collection.map((item: any) => item.id)
        })
      }
    }
  }

  private onSendToChange() {
    this.email.meta.recipients = []
    this.items = []
  }

  private getItemTranslation(item: any) {
    switch (this.email.meta.choose_from) {
      case 'clients': {
        if (item.main_email) {
          return `${getTranslations(item)} (${item.main_email})`
        }
        return getTranslations(item)
      }
      case 'users': {
        return getTranslations(item)
      }
      case 'clients_groups': {
        return getTranslations(item, ['title'])
      }
      default: {
        return ''
      }
    }
  }

  private async getRemoteList(query: string) {
    this.listLoading = true
    switch (this.email.meta.choose_from) {
      case 'clients': {
        this.items = await getRemoteClients(query, [{
          type: FilterType.field,
          key: 'main_email',
          value: '@',
          operator: FilterOperator.contains
        }])
        break
      }
      case 'clients_groups': {
        this.items = await getRemoteGroups(query)
        break
      }
      case 'users': {
        this.items = await getRemoteUsers(query, [{
          type: FilterType.field,
          key: 'main_email',
          value: '@',
          operator: FilterOperator.contains
        }])
        break
      }
      default: {
        break
      }
    }
    this.listLoading = false
  }

  private async getRemotePropertiesList(query: string) {
    const items = getPublicAndOfflineRequiredFilters()
    this.propertiesLoading = true
    this.properties = await getRemoteProperties(query, items)
    this.propertiesLoading = false
  }

  private handleOnChange(file: any, fileList: any) {
    this.files = fileList
  }

  private handleRemove(file: any, fileList: any) {
    this.files = fileList
  }

  private backToForm() {
    this.previewEmail = null
  }

  private clearValues() {
    try {
      (this.$refs.emailForm as Form).resetFields()
    } catch (e) {}
  }

  private prepareEmail() {
    const formData = new FormData()
    formData.append('email', JSON.stringify(this.email))
    for (let i = 0; i < this.files.length; i++) {
      if ((this.files[i].raw)) {
        formData.append('attachments', this.files[i].raw)
      }
    }
    return formData
  }

  private async previewItem() {
    const [isValid] = await validateForm(this.$refs.form as Form)
    if (!isValid) return errorMsg('form.formErrors')
    const { data } = await emailPreview(this.email)
    this.previewEmail = data
  }

  private async submitForm() {
    try {
      const { data } = await saveEmailWithMedia(this.prepareEmail())
      await sendEmail({ id: data.id })
      return this.$router.push('/internal-mail/list')
    } catch (e) {
      errorMsg('api.serverError')
    }
  }
}
